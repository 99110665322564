
import React, {useEffect, useRef} from 'react';
import "./landing.css"
import {useIsVisible} from "../../common/hooks/screenVisible";



interface VideoLandingProps {
    src:string
}
const VideoLanding:React.FC<VideoLandingProps> = ({src}) => {
    const targetRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null)
    const isVisible = useIsVisible(targetRef)

    useEffect(() => {
        if (!isVisible && videoRef && videoRef.current) {
            videoRef.current.pause()
        }
        if (isVisible && videoRef && videoRef.current) {
            videoRef.current.play()
        }
    }, [isVisible]);

    return (
        <div className="landing-page-outer vh-container">
            <div className="landing-page" ref={targetRef}>
                <video autoPlay muted loop ref={videoRef}>
                    <source src={src} type="video/mp4"/>
                    Your browser does not support video.
                </video>
            </div>
        </div>
    );
};

export default VideoLanding;