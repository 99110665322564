import React, {useEffect, useRef, useState} from 'react';
import { useInView } from 'react-intersection-observer';
import "./zoomimage.css"
interface ZoomImageProps {
    src:string;
}

const ZoomingImage: React.FC<ZoomImageProps> = ({src}) => {
    const [isImageVisible, setImageVisible] = useState(false);
    const targetDivRef = useRef<HTMLDivElement>(null);


    const handleIntersection: IntersectionObserverCallback = (entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setImageVisible(true)
            }
            else {
                setImageVisible(false)
            }
        });
    };


    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.7, // Adjust the threshold as needed
        });
        if (targetDivRef.current) {
            observer.observe(targetDivRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    const zoomInAnimation: React.CSSProperties = {
        transform: isImageVisible ? 'scale(1.1)' : 'scale(1)',
        transition: 'transform 0.5s ease-in-out',
    };


    useEffect(() => {
        console.log("Rescale")
    }, [isImageVisible]);

    return (
        <div ref={targetDivRef} className="zooming-image-container">
            <img
                src={src}
                alt="Your Image"
                style={{ ...zoomInAnimation }}
                onLoad={() => setImageVisible(true)}
            />
        </div>
    );
};

export default ZoomingImage;
