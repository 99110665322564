import React, {useEffect} from 'react';
import ZoomImage from "../../components/zoom_image/ZoomImage";
import SubCatPage from "../../components/subcatpage/SubCatPage";
import SubCatPageHeader from "../../components/subcatpage/SubCatPageHeader";

const Entertainment = () => {
    useEffect(() => {
        scrollToSection()
    }, []);

    const scrollToSection = () => {
        console.log("Scroll to top triggered")
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({behavior: "instant" as ScrollBehavior});
        }
    };

    return (
        <div>
            <SubCatPageHeader img={"pictures/03 evenMo Grafiken/Entertainment/SVG/ENTERTAINMENT WEISS ROT.svg"}/>
            <SubCatPage text={"We know how to create wow!!!"}
                        picture={"pictures/02 Fotos/03 Entertainment/02 Entertainment Seite/evenMo 3x3 Crowed.jpg"}
                        main_text={"Weil gute Stimmung kein Zufall ist."}
                        color={"#ce0505"}
                        isOnRightSide={false}
                        distanceToTop={20}
            />
            <SubCatPage text={"Entertainment für die Ohren"}
                        main_text={"Das perfekte Zusammenspiel von MC und DJ: Mit maßgeschneidertem und selbst produziertem Sounddesign für Ihr Event."}
                        picture={"pictures/02 Fotos/03 Entertainment/02 Entertainment Seite/evenMo 3x3 WM Stari.jpg"}
                        color={"#ce0505"}
                        isOnRightSide={true}
                        distanceToTop={20}
            />

            <SubCatPage text={"Entertainment für die Augen"}
                        main_text={"LED-Banden und LED-SCREENS: Organisation, Gestaltung und LIVE-Bedienung mit unseren innovativen Entertainment-Systemen. Ein Knopfdruck -> unzählige Emotionen!"}
                        picture={"pictures/02 Fotos/03 Entertainment/02 Entertainment Seite/evenMo Fans.jpg"}
                        color={"#ce0505"}
                        isOnRightSide={false}
                        distanceToTop={26}/>
            <SubCatPage text={"Entertainment zum mitmachen"}
                        main_text={" FAN CAM UND PUBLIKUM-ANIMATION: Wir gestalten nicht nur Ihre gewünschte Fan-Cam nach individuellem Design, sondern auch Interaktion mit den Zuschauern gemeinsam mit unseren Partnern von: BEST-MOTIVATION.DE"}
                        picture={"pictures/02 Fotos/03 Entertainment/02 Entertainment Seite/1.jpg"}
                        color={"#ce0505"}
                        isOnRightSide={true}
                        distanceToTop={20}/>
            <SubCatPage text={"Ihr Entertainment Paket aus einer Hand"}
                        main_text={"Von der ersten Idee bis zum letzten Applaus – wir sind Ihr Partner für herausragendes Entertainment; - and EvenMore"}
                        picture={"pictures/02 Fotos/03 Entertainment/02 Entertainment Seite/evenMo 3x3 WM.jpg"}
                        color={"#ce0505"}
                        isOnRightSide={true}
                        distanceToTop={30}
            />
        </div>
    );
};

export default Entertainment;
