import "./index.css"
import "./globals.css"
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React from "react";
import Layout from "./components/layout/Layout";
import Home from "./views/home/Home";
import Events from "./views/Events/Events";

import Entertainment from "./views/Entertainment/Entertainment";
import Livestream from "./views/Livestream/Livestream";
import Moderation from "./views/Moderation/Moderation";
import TermsAndConditions from "./views/legal/TermsAndConditions";
import PrivacyConditions from "./views/legal/PrivacyConditions";
import Impressum from "./views/legal/Impressum";


export default function MyRouter() {
    return (
        <>
            <BrowserRouter>
                    <Routes>
                        <Route path={"/"} element={<Layout/>}>
                            <Route index element={<Home/>}/>
                            <Route path={"/events"} element={<Events/>}/>
                            <Route path={"/entertainment"} element={<Entertainment/>}/>
                            <Route path={"/livestream"} element={<Livestream/>}/>
                            <Route path={"/moderation"} element={<Moderation/>}/>
                            <Route path={"/datenschutz"} element={<PrivacyConditions/>}/>
                            <Route path={"/agb"} element={<TermsAndConditions/>}/>
                            <Route path={"/impressum"} element={<Impressum/>}/>
                        </Route>
                    </Routes>
            </BrowserRouter>
        </>

    )
}
