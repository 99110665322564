import React, {useState} from 'react';
import {NavItemProps} from "./NavbarNew";
import "./navbarmobile.css"
import {useNavigate} from "react-router";

type NavbarMobileProps = {
    navItems: NavItemProps[];
    scrollToSection: (section: string) => void;
    hoveredItem: NavItemProps | undefined;
    setHoveredItem: (item: NavItemProps | undefined) => void;
    onKontaktClick: () => void;
};

const NavbarMobile: React.FC<NavbarMobileProps> = ({
                                                       scrollToSection,
                                                       setHoveredItem,
                                                       hoveredItem,
                                                       navItems,
                                                       onKontaktClick
                                                   }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav>
            <ul>
                <li className={"navbar-logo"}>
                    <img alt="Logo" src={"pictures/03 evenMo Grafiken/Logo/SVG/evenMo Logo MUSTERsvg.svg"}
                         onClick={() => scrollToSection("start")}/>
                </li>

                <li>
                    <div
                        className={`hamburger-menu ${menuOpen ? 'open' : ''}`}
                        onClick={toggleMenu}
                    >
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </li>
            </ul>
            <ul className={`navbar-mobile-links ${menuOpen ? 'show' : ''}`}>
                {navItems.map((item) => (
                    <li key={item.name} className={item.classname}>
                        <button
                            onClick={() => {
                                navigate(item.link);
                                setMenuOpen(false); // Close menu on selection
                            }}
                            onMouseEnter={() => setHoveredItem(item)}
                            onMouseLeave={() => setHoveredItem(undefined)}
                        >
                            <img
                                alt={item.name}
                                src={hoveredItem && hoveredItem.name === item.name ? item.pic_coloured : item.pic}
                            />
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default NavbarMobile;
