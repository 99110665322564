import React from 'react';

import "./category.scss"
import {Fade} from "react-awesome-reveal";
import {useNavigate} from "react-router";
import ZoomImage from "../zoom_image/ZoomImage";

interface EventsProps {
    src: string
}

const EventContainer: React.FC<EventsProps> = ({src}) => {
    const navigate = useNavigate()

    return (
        <div className="vh-container flex overflow-hidden">
            <ZoomImage src={src}/>
            <div className="category-overlay__container overflow-hidden">
                <Fade cascade={true} direction="left" triggerOnce={true} delay={200}>
                    <div className={"category-overlay__home-logo"}>
                        <img src={"pictures/05 Special/eventsDreieck.png"} alt={"eventsLogo"}/>
                    </div>
                </Fade>
                <Fade cascade={true} direction="right" triggerOnce={true} delay={50}>
                    <div className="category-overlay__showmore">
                        <div className={"category-overlay__showmore-inner-events"}>
                            {/*TODO EVENMORE svg*/}
                            <button className="button-89" role="button" onClick={() => navigate("/events")}>Show
                                evenMore
                            </button>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    );
};

export default EventContainer;