import React from 'react';
import NavbarNew from "../navbar/NavbarNew";
import Footer from "../footer/Footer";
import {Outlet} from "react-router-dom";


const Layout: React.FC = ({}) => {
    return (
        <>
            <NavbarNew/>
            <Outlet/>
            <Footer/>
        </>
    );
};

export default Layout;