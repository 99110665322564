import React, {useState} from 'react';
import ZoomImage from "../zoom_image/ZoomImage";
import SlideInText from "../slideintext/SlideInText";
import "./popup.css"
import "./watermark.css"
import Popup from "./PopUp";
import {useMediaQuery} from "react-responsive";
import SubcatDesktop from "./SubcatDesktop";
import SubcatMobile from "./SubcatMobile";

export interface SubCatPageProps {
    text: string;
    picture: string;
    color: string;
    main_text?: string;
    img_watermark?: string;
    img_watermark_link?: string;
    isOnRightSide?: boolean;
    distanceToTop?: number;
}

const SubCatPage: React.FC<SubCatPageProps> = ({
                                                   text,
                                                   picture,
                                                   color,
                                                   main_text,
                                                   img_watermark_link,
                                                   img_watermark,
                                                   isOnRightSide,
                                                   distanceToTop
                                               }) => {
    const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'});
    const isTablet = useMediaQuery({query: '(max-width: 1224px)'});
    const isMobile = useMediaQuery({query: '(max-width: 700px)'});

    return (
        <div className="vh-container flex overflow-hidden relative" id="start">
            {isDesktopOrLaptop || isTablet && !isMobile ? (
                <SubcatDesktop text={text} picture={picture} color={color} curText={text}
                               isOnRightSide={isOnRightSide}
                               distanceToTop={distanceToTop} main_text={main_text}
                               img_watermark={img_watermark}/>) : isMobile ?
                <SubcatMobile text={text} picture={picture} color={color} curText={text}
                              isOnRightSide={isOnRightSide}
                              distanceToTop={distanceToTop} main_text={main_text}
                              img_watermark={img_watermark}/> : null

            }
        </div>
    );
};

export default SubCatPage;
