import React from 'react';
import { Fade } from 'react-awesome-reveal';
import './headersubcat.css';

interface Props {
    img: string
}

const SubCatPageHeader: React.FC<Props> = ({ img }) => {
    return (
        <div className="header-image-container">
            <Fade triggerOnce>
                <img src={img} alt="Header" className="scroll-animation" />
            </Fade>
        </div>
    );
};

export default SubCatPageHeader;
