import React, {useEffect, useRef, useState} from 'react';
import "./evenmocycle.css"

import {Fade} from "react-awesome-reveal";
import {useIsVisible} from "../../common/hooks/screenVisible";

const EvenMoCycle = () => {
    const [timer, setTimer] = useState<number>(0)
    const [updates, setUpdates] = useState<number>(0);
    const [pic, setPic] = useState<string>("")
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useIsVisible(ref)


    useEffect(() => {
        if (isVisible) {
            const intervalId = setInterval(() => {
                setTimer(prevTimer => {
                    const newTimer = prevTimer + 1;
                    if (updates < 10) {
                        setUpdates(prevUpdates => prevUpdates + 1);
                    } else {
                        clearInterval(intervalId);
                    }
                    return newTimer;
                });
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [updates, isVisible]);


    useEffect(() => {
        switch (timer) {
            case 1:
                setPic("pictures/03 evenMo Grafiken/Events/SVG/EVENTS WEISS GRUEN.svg")
                break;
            case 2:
                setPic("pictures/03 evenMo Grafiken/Entertainment/SVG/ENTERTAINMENT WEISS ROT.svg")
                break;
            case 3:
                setPic("pictures/03 evenMo Grafiken/Moderation/SVG/Moderation weiß Blau.svg")
                break;
            case 4:
                setPic("pictures/03 evenMo Grafiken/Livestream/SVG/Livestream Weiss Organge.svg")
                break;
            default:
                setPic("pictures/03 evenMo Grafiken/Logo/SVG/evenMo.svg")
                break;

        }
    }, [timer]);


    return (
        <div  className="cycle-container flex flex-col justify-evenly items-center ">
            {/*<h1 ref={ref}>Herzlich Willkommen in der Welt von:</h1>*/}
            {/*    <img alt="pic" src={pic}/>*/}
        </div>
    );
};

export default EvenMoCycle;