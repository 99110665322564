import React, {useEffect, useRef} from 'react';

import "./home.css"
import {Fade} from "react-awesome-reveal";
import VideoLanding from "../../components/landing/VideoLanding";
import EvenMoCycle from "../../components/evenmocycle/EvenMoCycle";
import EventContainer from "../../components/category/EventContainer";
import EntertainmentContainer from "../../components/category/EntertainmentContainer";
import ModerationContainer from "../../components/category/ModerationContainer";
import LiveStream from "../../components/category/LivestreamContainer";
import KundenPartnerContainer from "../../components/category/KundenPartnerContainer";
import InstagramFeed from "../../components/instafeed/InstagramFeed";

const Home = () => {

    const eventsMainImg: string = "pictures/02 Fotos/01 Events/01 Startseite/evenMo Fm4.jpg"
    const entertainmentMainImg: string = "pictures/02 Fotos/03 Entertainment/01 Startseite/evenMo 3x3 WM Stari.jpg"
    const moderationMainImg: string = "pictures/02 Fotos/02 Moderationen/01 Startseite/evenMo 3x3 Pk gross.jpg"
    const livestreamMainImg: string = "pictures/02 Fotos/04 Livestream/01 Startseite/evenMo Basketball Produktion.jpg"


    return (
        <div>
            <div className="vh-container" id="start">
                <VideoLanding src={"videos/video.mp4"}/>
            </div>
            <EvenMoCycle/>

            <div className="events" id="events">
                <EventContainer src={eventsMainImg}/>
            </div>
            <div className="entertainment" id="entertainment">
                <EntertainmentContainer src={entertainmentMainImg}/>
            </div>
            <div className="moderation" id="moderation">
                <ModerationContainer src={moderationMainImg}/>
            </div>
            <div className="livestream" id="livestream">
                <LiveStream src={livestreamMainImg}></LiveStream>
            </div>
            <div className="kunden" id="kunden">
                <KundenPartnerContainer/>
            </div>
            {/*<div className="home-evenMore">*/}
            {/*    <h1>And</h1>*/}
            {/*    <Fade cascade={true} direction="left" triggerOnce={true} delay={100}>*/}
            {/*        <img src={"pictures/03 evenMo Grafiken/evenMore/svg/evenMore Schwarz.svg"} alt="evenmore"/>*/}
            {/*    </Fade>*/}
            {/*</div>*/}
            {/*<div className="evenmore" id="evenmore">*/}
            {/*    <InstagramFeed></InstagramFeed>*/}
            {/*</div>*/}
        </div>
    );
};

export default Home;