import React, {useEffect} from 'react';

const TermsAndConditions: React.FC = () => {
    useEffect(() => {
        scrollToSection()
    }, []);
    const scrollToSection = (): void => {
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({behavior: "instant" as ScrollBehavior});
        }
    };
    return (
        <div id={"start"} style={styles.container}>
            <h1  style={styles.header}>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</h1>

            <section>
                <h2 style={styles.subHeader}>1. Geltung, Vertragsabschluss</h2>
                <ol style={styles.list}>
                    <li>
                        EVENMO erbringt ihre Leistungen ausschließlich auf der Grundlage der nachfolgenden Allgemeinen
                        Geschäftsbedingungen (AGB). Diese gelten für alle Rechtsbeziehungen zwischen EVENMO und dem
                        Kunden, selbst wenn nicht ausdrücklich auf sie Bezug genommen wird.
                    </li>
                    <li>
                        Maßgeblich ist jeweils die zum Zeitpunkt des Vertragsschlusses gültige Fassung. Abweichungen von
                        diesen sowie sonstige ergänzende Vereinbarungen mit dem Kunden sind nur wirksam, wenn sie von
                        EVENMO schriftlich bestätigt werden.
                    </li>
                    <li>
                        Allfällige Geschäftsbedingungen des Kunden werden, selbst bei Kenntnis, nicht akzeptiert, sofern
                        nicht im Einzelfall ausdrücklich und schriftlich anderes vereinbart wird. AGB des Kunden
                        widerspricht EVENMO ausdrücklich. Eines weiteren Widerspruchs gegen AGB des Kunden durch EVENMO
                        bedarf es nicht.
                    </li>
                    <li>
                        Änderungen der AGB werden dem Kunden bekannt gegeben und gelten als vereinbart, wenn der Kunde
                        den geänderten AGB nicht schriftlich binnen 14 Tagen widerspricht; auf die Bedeutung des
                        Schweigens sowie auf die konkret geänderten Klauseln wird der Kunde in der Verständigung
                        ausdrücklich hingewiesen. Diese Zustimmungsfiktion gilt nicht für die Änderung wesentlicher
                        Leistungsinhalte und Entgelte.
                    </li>
                    <li>
                        Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein, so berührt
                        dies die Verbindlichkeit der übrigen Bestimmungen und der unter ihrer Zugrundelegung
                        geschlossenen Verträge nicht. Die unwirksame Bestimmung ist durch eine wirksame, die dem Sinn
                        und Zweck am nächsten kommt, zu ersetzen.
                    </li>
                </ol>
            </section>

            <section>
                <h2 style={styles.subHeader}>2. Fremdleistungen / Beauftragung Dritter</h2>
                <ol style={styles.list}>
                    <li>
                        EVENMO ist nach freiem Ermessen berechtigt, die Leistung selbst auszuführen, sich bei der
                        Erbringung von vertragsgegenständlichen Leistungen sachkundiger Dritter als Erfüllungsgehilfen
                        zu bedienen und/oder derartige Leistungen zu substituieren („Fremdleistung“).
                    </li>
                    <li>
                        Die Beauftragung von Dritten im Rahmen einer Fremdleistung erfolgt entweder im eigenen Namen
                        oder im Namen des Kunden, letztere nach vorheriger Information an den Kunden. EVENMO wird diesen
                        Dritten sorgfältig auswählen und darauf achten, dass dieser über die erforderliche fachliche
                        Qualifikation verfügt.
                    </li>
                </ol>
            </section>

            <section>
                <h2 style={styles.subHeader}>3. Vorzeitige Auflösung</h2>
                <ol style={styles.list}>
                    <li>
                        EVENMO ist berechtigt, den Vertrag aus wichtigen Gründen mit sofortiger Wirkung aufzulösen. Ein
                        wichtiger Grund liegt insbesondere vor, wenn
                        die Ausführung der Leistung aus Gründen, die der Kunde zu vertreten hat, unmöglich wird
                        oder trotz Setzung einer angemessenen Nachfrist weiter verzögert wird;
                        der Kunde fortgesetzt, trotz schriftlicher Abmahnung mit einer Nachfristsetzung von 7
                        Tagen, gegen wesentliche Verpflichtungen aus diesem Vertrag, wie z.B. Zahlung eines
                        fällig gestellten Betrages oder Mitwirkungspflichten, verstößt.

                    </li>
                </ol>
            </section>

            <section>
                <h2 style={styles.subHeader}>4. Zahlung</h2>
                <ol style={styles.list}>
                    <li>
                        Das Honorar ist sofort mit Rechnungserhalt und ohne Abzug zur Zahlung fällig, sofern nicht im
                        Einzelfall besondere Zahlungsbedingungen schriftlich vereinbart werden. Dies gilt auch für die
                        Weiterverrechnung sämtlicher Barauslagen und sonstiger Aufwendungen.
                    </li>
                    <li>
                        Bei Zahlungsverzug des Kunden gelten die gesetzlichen Verzugszinsen in der für
                        Unternehmergeschäfte geltenden Höhe. Weiters verpflichtet sich der Kunde für den Fall des
                        Zahlungsverzugs, der EVENMO die entstehenden Mahn- und Inkassospesen, soweit sie zur
                        zweckentsprechenden Rechtsverfolgung notwendig sind, zu ersetzen. Dies umfasst jedenfalls die
                        Kosten zweier Mahnschreiben in marktüblicher Höhe von derzeit zumindest € 20,00 je Mahnung sowie
                        eines Mahnschreibens eines mit der Eintreibung beauftragten Rechtsanwalts. Die Geltendmachung
                        weitergehender Rechte und Forderungen bleibt davon unberührt.
                    </li>
                    <li>
                        Im Falle des Zahlungsverzuges des Kunden kann EVENMO sämtliche, im Rahmen anderer mit dem Kunden
                        abgeschlossener Verträge, erbrachten Leistungen und Teilleistungen sofort fällig stellen.
                    </li>
                    <li>
                        Weiters ist EVENMO nicht verpflichtet, weitere Leistungen bis zur Begleichung des aushaftenden
                        Betrages zu erbringen (Zurückbehaltungsrecht). Die Verpflichtung zur Entgeltzahlung bleibt davon
                        unberührt.
                    </li>
                    <li>
                        Der Kunde ist nicht berechtigt, mit eigenen Forderungen gegen Forderungen der EVENMO
                        aufzurechnen, außer die Forderung des Kunden wurde von der EVENMO schriftlich anerkannt oder
                        gerichtlich festgestellt.
                    </li>
                </ol>
            </section>

            <section>
                <h2 style={styles.subHeader}>5. Kennzeichnung</h2>
                <ol style={styles.list}>
                    <li>
                        EVENMO ist berechtigt, auf allen Werbemitteln und bei allen Werbemaßnahmen auf die EVENMO und
                        allenfalls auf den Urheber hinzuweisen, ohne dass dem Kunden dafür ein Entgeltanspruch zusteht.
                    </li>
                    <li>
                        EVENMO ist vorbehaltlich des jederzeit möglichen, schriftlichen Widerrufs des Kunden dazu
                        berechtigt,
                        auf eigenen Werbeträgern und insbesondere auf ihrer Internet-Website mit Namen und Firmenlogo
                        auf
                        die
                        zum Kunden bestehende oder vormalige Geschäftsbeziehung hinzuweisen (Referenzhinweis).
                    </li>
                </ol>
            </section>

            <section>
            <h2 style={styles.subHeader}>6. Haftung und Produkthaftung</h2>
                <ol style={styles.list}>
                    <li>
                        In Fällen leichter Fahrlässigkeit ist eine Haftung der EVENMO und die ihrer Angestellten,
                        Auftragnehmer
                        oder sonstigen Erfüllungsgehilfen („Leute“) für Sach- oder Vermögensschäden des Kunden
                        ausgeschlossen,
                        gleichgültig ob es sich um unmittelbare oder mittelbare Schäden, entgangenen Gewinn oder
                        Mangelfolgeschäden, Schäden wegen Verzugs, Unmöglichkeit, positiver Forderungsverletzung,
                        Verschuldens
                        bei Vertragsabschluss, wegen mangelhafter oder unvollständiger Leistung handelt. Das Vorliegen
                        von
                        grober Fahrlässigkeit hat der Geschädigte zu beweisen. Soweit die Haftung der EVENMO
                        ausgeschlossen oder
                        beschränkt ist, gilt dies auch für die persönliche Haftung ihrer „Leute“.
                    </li>
                    <li>
                        Jegliche Haftung der EVENMO für Ansprüche, die auf Grund der von der EVENMO erbrachten Leistung
                        (z.B.
                        Werbemaßnahme) gegen den Kunden erhoben werden, wird ausdrücklich ausgeschlossen, wenn EVENMO
                        ihrer
                        Hinweispflicht nachgekommen ist oder eine solche für sie nicht erkennbar war, wobei leichte
                        Fahrlässigkeit nicht schadet. Insbesondere haftet EVENMO nicht für Prozesskosten, eigene
                        Anwaltskosten
                        des Kunden oder Kosten von Urteilsveröffentlichungen sowie für allfällige
                        Schadenersatzforderungen oder
                        sonstige Ansprüche Dritter; der Kunde hat EVENMO diesbezüglich schad- und klaglos zu halten.
                    </li>
                    <li>Schadensersatzansprüche des Kunden verfallen in sechs Monaten ab Kenntnis des Schadens;
                        jedenfalls aber
                        nach drei Jahren ab der Verletzungshandlung der EVENMO. Schadenersatzansprüche sind der Höhe
                        nach mit
                        dem Netto-Auftragswert begrenzt.
                    </li>
                </ol>
            </section>

            <section>
                <h2 style={styles.subHeader}>7. Anzuwendendes Recht</h2>
                <ol style={styles.list}>
                    <li>
                        Der Vertrag und alle daraus abgeleiteten wechselseitigen Rechte und Pflichten sowie Ansprüche
                        zwischen der
                        EVENMO und dem Kunden unterliegen dem österreichischen materiellen Recht unter Ausschluss seiner
                        Verweisungsnormen und unter Ausschluss des UN-Kaufrechts.
                    </li>
                </ol>
            </section>
        </div>
)
;
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        padding: '3rem',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: "black",

        backgroundColor: "white"
    },
    header: {
        marginTop: '5rem',
        fontSize: '28px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
    },
    list: {
        marginLeft: '20px',
        marginBottom: '20px',
    },
    subList: {
        marginLeft: '20px',
    },
};

export default TermsAndConditions;
