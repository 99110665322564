import React from 'react';
import "./remover.css"

const Impressum: React.FC = () => {
    return (
        <div id={"start"} style={styles.container}>
            <h1 style={styles.header}>Impressum</h1>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>Name</h2>
                <p>
                    Rafael Dobler, Bakk.
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>Gewerbeadresse</h2>
                <p>
                    Alfred Horn Straße 4 Top 6<br/>
                    2320 Schwechat
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>Umsatzsteuer-ID</h2>
                <p>
                    ATU67648236
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>Gewerbebehörde</h2>
                <p>
                    Bezirkshauptmannschaft Bruck an der Leitha
                </p>
            </section>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        padding: '3rem',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: "black",
        backgroundColor: "white"
    },
    header: {
        marginTop: '5rem',
        fontSize: '28px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
    },
    section: {
        marginBottom: '20px',
    }
};

export default Impressum;
