import React from 'react';
import { Fade } from "react-awesome-reveal";
import CSS from "csstype";
import {useMediaQuery} from "react-responsive";

interface SlideInTextProps {
    text: string,
    color: string,
    isOnRightSide: boolean,
    distanceToTop: number,
    main_text?: string
}

const SlideInText: React.FC<SlideInTextProps> = ({ text, distanceToTop, isOnRightSide, color, main_text }) => {

    const isTablet = useMediaQuery({query: '(max-width: 1400px)'});


    const componentTextStyles: CSS.Properties = {
        backgroundColor: color,
        height: "fit-content",
        padding: '1rem',
        fontSize: isTablet?"1.5rem":"3rem",
        fontWeight: 'bold',
        paddingLeft: isOnRightSide ? "10rem" : "5rem",
        paddingRight: !isOnRightSide ? "10rem" : "5rem",
        marginTop: `${distanceToTop}vh`,
        clipPath: !isOnRightSide ? "polygon(0 0, 100% 0, 87% 100%, 0 100%)" : "polygon(13% 0%, 100% 0, 100% 100%, 0 100%)",
    }

    const componentMainTextStyles: CSS.Properties = {
        height: "fit-content",
        padding: '1rem',
        fontSize: "2rem",
        paddingLeft: isOnRightSide ? "10rem" : "5rem",
        paddingRight: !isOnRightSide ? "10rem" : "5rem",
        backgroundColor: "rgba(255, 255, 255, .75)",
        backdropFilter: "blur(15px)",
        marginTop: '1rem',
        color:"black"
    }

    const componentOuterStyles: CSS.Properties = {
        position: "absolute",
        zIndex: 20,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: isOnRightSide ? "flex-end" : "flex-start",
        gap: "1rem",
        marginTop: `${distanceToTop}vh`,

    }

    return (
        <div style={componentOuterStyles}>
            <Fade cascade={true} direction={isOnRightSide ? "right" : "left"} triggerOnce={true} delay={100}>
                <div style={componentTextStyles}>
                    {text.toUpperCase()}
                </div>
            </Fade>
            {main_text && (
                <Fade cascade={true} direction={isOnRightSide ? "right" : "left"} triggerOnce={true} delay={200} style={{maxWidth:isTablet?"100%":"50%"}}>

                    <div style={componentMainTextStyles}>
                        {main_text}
                    </div>
                </Fade>
            )}
        </div>
    );
};

export default SlideInText;
