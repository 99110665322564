import React from 'react';
import "./footer.css"
import {NavLink, useLocation} from "react-router-dom";
import {FaInstagram} from "react-icons/fa";
import {FaFacebook} from "react-icons/fa";
import {FaLinkedin} from "react-icons/fa";

const Footer = () => {
    return (
        <>
            <div className="footer-container">
                <div className="footer-top">
                    <div className="footer-logosection">
                        <img alt={"logo"}
                             src={"pictures/03 evenMo Grafiken/Logo/SVG/evenMo Logo MUSTERsvg.svg"}/>
                    </div>
                    <div className="footer-owner">
                        <p>Rafael Dobler</p>
                        <p>Alfred Horn Straße 4 Top 6</p>
                        <p>2320 Schwechat</p>
                        <p>dobler@evenmo.at</p>
                    </div>
                </div>
                <hr/>
                <div className="footer-socials">
                    <a href="https://www.instagram.com/evenmo_?igsh=ZTZzMmZob2g1cHNh" target="_blank">
                        <FaInstagram/>
                    </a>
                    <a href={"https://www.facebook.com/Events.Moderationen/?locale=de_DE"} target="_blank">
                        <FaFacebook/>
                    </a>
                    <a href={"https://www.linkedin.com/in/rafael-dobler-63ba91151/?originalSubdomain=at"} target="_blank">
                        <FaLinkedin/>
                    </a>
                </div>
                <div className="footer-legal flex justify-evenly align-middle">
                    <NavLink to={"/datenschutz"}>Datenschutz</NavLink>
                    <NavLink to={"/agb"}>AGB</NavLink>
                    <NavLink to={"/impressum"}>Impressum</NavLink>
                </div>
            </div>
        </>

    )
        ;
};

export default Footer;