/**
 * author: simon
 * date: 02.08.2024
 * project: EvenMoWebsite
 * package_name:
 **/

import React from 'react';
import ZoomImage from "../zoom_image/ZoomImage";
import SlideInText from "../slideintext/SlideInText";


export interface SubCatPagePropsDesktop {
    text: string;
    picture: string;
    color: string;
    main_text?: string;
    img_watermark?: string;
    img_watermark_link?: string;
    curText: string;
    isOnRightSide: boolean;
    distanceToTop: number;
}

const SubcatDesktop:React.FC<SubCatPagePropsDesktop> = ({isOnRightSide,curText,distanceToTop,text,picture,main_text,img_watermark_link,img_watermark,color}) => {
    return (
        <>
            <ZoomImage src={picture}/>
            <SlideInText
                text={curText}
                color={color}
                isOnRightSide={isOnRightSide}
                distanceToTop={distanceToTop}
                main_text={main_text}
            />
            {img_watermark && (
                <a
                    href={img_watermark_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`watermark ${isOnRightSide ? 'left' : 'right'}`}
                >
                    <span>{img_watermark}</span>
                </a>
            )}
        </>
    );
};

export default SubcatDesktop;