import React, { useState } from 'react';
import "./navbarnew.css";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import NavbarDesktop from "./NavbarDesktop";
import NavbarTablet from "./NavbarTablet";
import NavbarMobile from "./NavbarMobile";
import KontaktPopUp from "./KontaktPopUp";


export interface NavItemProps {
    name: string;
    pic: string;
    pic_coloured?: string;
    classname?: string;
    link: string;
}

const NavbarNew: React.FC = () => {
    const location = useLocation();
    const [hoveredItem, setHoveredItem] = useState<NavItemProps | null>(null);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

    const navItems: NavItemProps[] = [
        {
            name: "Events",
            pic: "pictures/03 evenMo Grafiken/Events/SVG/EVENTS WEISS.svg",
            pic_coloured: "pictures/03 evenMo Grafiken/Events/SVG/EVENTS WEISS GRUEN.svg",
            classname: "navbar-events",
            link: "/events"
        },
        {
            name: "Entertainment",
            pic: "pictures/03 evenMo Grafiken/Entertainment/SVG/ENTERTAINMENT WEISS.svg",
            pic_coloured: "pictures/03 evenMo Grafiken/Entertainment/SVG/ENTERTAINMENT WEISS ROT.svg",
            classname: "navbar-entertainment",
            link: "/entertainment"
        },
        {
            name: "Moderation",
            pic: "pictures/03 evenMo Grafiken/Moderation/SVG/Moderation weiß.svg",
            pic_coloured: "pictures/03 evenMo Grafiken/Moderation/SVG/Moderation weiß Blau.svg",
            classname: "navbar-moderation",
            link: "/moderation"
        },
        {
            name: "Livestream",
            pic: "pictures/03 evenMo Grafiken/Livestream/SVG/Livestream weiss.svg",
            pic_coloured: "pictures/03 evenMo Grafiken/Livestream/SVG/Livestream Weiss Organge.svg",
            classname: "navbar-livestream",
            link: "/livestream"
        }
    ];

    const scrollToSection = (id: string): void => {
        const element = document.getElementById(id.toLowerCase());
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <div className="navbar-bg"></div>
            {location.pathname === "/" ? (
                isDesktopOrLaptop && !isTablet ? (
                    <NavbarDesktop
                        navItems={navItems}
                        scrollToSection={scrollToSection}
                        hoveredItem={hoveredItem}
                        setHoveredItem={setHoveredItem}
                        onKontaktClick={() => setIsPopUpOpen(true)}
                    />
                ) : isTablet && !isMobile ? (
                    <NavbarTablet
                        navItems={navItems}
                        scrollToSection={scrollToSection}
                        hoveredItem={hoveredItem}
                        setHoveredItem={setHoveredItem}
                        onKontaktClick={() => setIsPopUpOpen(true)}
                    />
                ) : isMobile ? (
                    <NavbarMobile
                        navItems={navItems}
                        scrollToSection={scrollToSection}
                        hoveredItem={hoveredItem}
                        setHoveredItem={setHoveredItem}
                        onKontaktClick={() => setIsPopUpOpen(true)}
                    />
                ) : null
            ) : (
                <nav>
                    <ul className="navbar-page-standard">
                        <li className="navbar-logo">
                            <NavLink to="/">
                                <img alt="Logo" src="pictures/03 evenMo Grafiken/Logo/SVG/evenMo Logo MUSTERsvg.svg" />
                            </NavLink>
                        </li>
                        <li className={"navbar-kontakt"}>
                            <button onClick={() => setIsPopUpOpen(true)}><b>KONTAKT</b></button>
                        </li>
                    </ul>
                </nav>
            )}
            <KontaktPopUp isOpen={isPopUpOpen} onClose={() => setIsPopUpOpen(false)} />
        </>
    );
};

export default NavbarNew;
