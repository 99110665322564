import React from 'react';
import {NavItemProps} from "./NavbarNew";
import {useNavigate} from "react-router";



type NavbarDesktopProps = {
    navItems: NavItemProps[];
    scrollToSection: (section: string) => void;
    hoveredItem: NavItemProps | undefined;
    setHoveredItem: (item: NavItemProps | undefined) => void;
    onKontaktClick: ()=>void;
};

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({ onKontaktClick,navItems, scrollToSection, hoveredItem, setHoveredItem }) => {
    const navigate = useNavigate();
    return (
        <nav>
            <ul>
                <li className={"navbar-logo"}>
                    <img alt="Logo" src={"pictures/03 evenMo Grafiken/Logo/SVG/evenMo Logo MUSTERsvg.svg"}
                         onClick={() => scrollToSection("start")}/>
                </li>
                {navItems.map((item) => (
                    <>
                        <li key={`empty-${item.name}`} />
                        <li key={item.name} className={item.classname}>
                            <button onClick={() => navigate(item.link)}
                                    onMouseEnter={() => setHoveredItem(item)}
                                    onMouseLeave={() => setHoveredItem(undefined)}>
                                <img alt={item.name}
                                     src={hoveredItem && hoveredItem.name === item.name ? item.pic_coloured : item.pic}/>
                            </button>
                        </li>
                    </>
                ))}
                <li />
                <li className={"navbar-kontakt"}>
                    <button onClick={onKontaktClick}><b>KONTAKT</b></button>
                </li>
            </ul>
        </nav>
    );
};

export default NavbarDesktop;
