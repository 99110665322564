import React, { useEffect } from 'react';
import "./remover.css"

const PrivacyPolicy: React.FC = () => {
    useEffect(() => {
        scrollToSection()
    }, []);

    const scrollToSection = (): void => {
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({ behavior: "instant" as ScrollBehavior });
        }
    };

    return (
        <div id={"start"} style={styles.container}>
            <h1 style={styles.header}>Datenschutzerklärung</h1>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>1. Einleitung</h2>
                <p>
                    Diese Datenschutzerklärung (Fassung 09.01.2024-112695080) wurde erstellt, um Ihnen gemäß der
                    Datenschutz-Grundverordnung (EU) 2016/679 und den anwendbaren nationalen Gesetzen zu erläutern,
                    welche personenbezogenen Daten wir verarbeiten und welche Rechte Sie haben.
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>2. Kontakt</h2>
                <p>
                    Rafael Dobler<br />
                    Alfred Horn Straße 4 Top 6<br />
                    2320 Schwechat
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>3. Anwendungsbereich</h2>
                <p>
                    Diese Erklärung gilt für alle von uns verarbeiteten personenbezogenen Daten. Dazu zählen:
                </p>
                <p>Onlineauftritte (Websites, Onlineshops)</p>
                <p>Social Media Auftritte und E-Mail-Kommunikation</p>
                <p>Mobile Apps für Smartphones und andere Geräte</p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>4. Rechtsgrundlagen</h2>
                <p>
                    Die Verarbeitung Ihrer Daten erfolgt auf Basis der folgenden rechtlichen Grundlagen:
                </p>
                <p>
                    <strong>Einwilligung (Artikel 6 Absatz 1 lit. a DSGVO):</strong> Ihre Einwilligung zur
                    Verarbeitung zu bestimmten Zwecken.
                </p>
                <p>
                    <strong>Vertrag (Artikel 6 Absatz 1 lit. b DSGVO):</strong> Verarbeitung zur Erfüllung eines
                    Vertrages oder vorvertraglicher Verpflichtungen.
                </p>
                <p>
                    <strong>Rechtliche Verpflichtung (Artikel 6 Absatz 1 lit. c DSGVO):</strong> Erfüllung
                    gesetzlicher Pflichten, z. B. Buchhaltung.
                </p>
                <p>
                    <strong>Berechtigte Interessen (Artikel 6 Absatz 1 lit. f DSGVO):</strong> Verarbeitung zur
                    Wahrung berechtigter Interessen, z. B. Betrieb der Website.
                </p>
                <p>Weitere relevante Gesetze sind:</p>
                <p>Österreich: Datenschutzgesetz (DSG)</p>
                <p>Deutschland: Bundesdatenschutzgesetz (BDSG)</p>
                <p>Details finden Sie auf <a
                    href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679" target="_blank"
                    style={styles.link}>EUR-Lex</a>.</p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>5. Speicherdauer</h2>
                <p>
                    Wir speichern personenbezogene Daten nur so lange wie notwendig. Nach Wegfall des Zwecks werden
                    Daten gelöscht, es sei denn, gesetzliche Aufbewahrungspflichten bestehen.
                </p>
                <p>
                    Bei Löschungsanfragen oder Widerruf der Einwilligung werden Daten umgehend gelöscht, sofern keine
                    gesetzliche Aufbewahrungspflicht besteht.
                </p>
            </section>

            <section style={styles.section}>
                <h2 style={styles.subHeader}>6. Ihre Rechte</h2>
                <p>
                    Sie haben gemäß DSGVO folgende Rechte:
                </p>
                <p>
                    <strong>Auskunft (Artikel 15 DSGVO):</strong> Erhalten Sie Informationen über die Verarbeitung
                    Ihrer Daten.
                </p>
                <p>
                    <strong>Berichtigung (Artikel 16 DSGVO):</strong> Korrektur unrichtiger Daten.
                </p>
                <p>
                    <strong>Löschung (Artikel 17 DSGVO):</strong> Recht auf Vergessenwerden.
                </p>
                <p>
                    <strong>Einschränkung der Verarbeitung (Artikel 18 DSGVO):</strong> Daten nur noch speichern,
                    aber nicht weiter verwenden.
                </p>
                <p>
                    <strong>Datenübertragbarkeit (Artikel 20 DSGVO):</strong> Erhalt Ihrer Daten in einem gängigen
                    Format.
                </p>
                <p>
                    <strong>Widerspruch (Artikel 21 DSGVO):</strong> Widerspruch gegen die Verarbeitung Ihrer Daten.
                </p>
                <p>
                    <strong>Keine automatisierten Entscheidungen (Artikel 22 DSGVO):</strong> Vermeidung von
                    Entscheidungen, die ausschließlich auf automatisierter Verarbeitung basieren.
                </p>
                <p>
                    <strong>Beschwerderecht (Artikel 77 DSGVO):</strong> Recht, sich bei der Datenschutzbehörde zu
                    beschweren.
                </p>
            </section>
        </div>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        padding: '3rem',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: "black",
        backgroundColor: "white"
    },
    header: {
        marginTop: '5rem',
        fontSize: '28px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '20px',
    },
    subHeader: {
        fontSize: '22px',
        fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px',
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
    }
};

export default PrivacyPolicy;
