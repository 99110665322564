import React, {useEffect} from 'react';
import ZoomImage from "../../components/zoom_image/ZoomImage";
import SubCatPage from "../../components/subcatpage/SubCatPage";
import SubCatPageHeader from "../../components/subcatpage/SubCatPageHeader";

const Livestream = () => {
    useEffect(() => {
        scrollToSection()
    }, []);
    const scrollToSection = (): void => {
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({behavior: "instant" as ScrollBehavior});
        }
    };
    return (
        <div>
            <SubCatPageHeader img={"pictures/03 evenMo Grafiken/Livestream/SVG/Livestream Weiss Organge.svg"}/>
            <SubCatPage text={"Talk formate"}
                        main_text={"Inhaltlich spannende Themen abwechslungsreich in Szene gesetzt."}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/7 (1).jpg"}
                        color={"#e8ad05"}
                        isOnRightSide={false}
                        distanceToTop={31}
            />

            <SubCatPage text={"Interaktive Kongresse"}
                        main_text={"GEHT NICHT? GIBT´S NICHT! GEWUSS WIE: Nahezu unbegrenzte Möglichkeiten durch fortschrittliche Technik. MODERN, FLEXIBEL und EFFIZIENT. Und das alles zu einem leistbaren Preis. Erleben Sie es selbst!"}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/8.jpg"}
                        color={"#e8ad05"}
                        isOnRightSide={true}
                        distanceToTop={1}
            />

            <SubCatPage text={"Produzieren Sie Selbst!"}
                        main_text={"Mit unserer Hilfe und unserer innovativen Technik gestalten und realisieren Sie Ihren Livestream selbst! Wir kümmern uns um Planung, Einschulung und Support!"}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/9.jpg"}
                        color={"#e8ad05"}
                        isOnRightSide={true}
                        distanceToTop={24}
            />

            <SubCatPage text={"Wir sind Sportbesessen!"}
                        main_text={"Egal welche Sportart! Wir setzen jeden Punkt, jedes Tor, jede Bestleistung und jeden Schweißtropfen perfekt in Szene."}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/11.jpg"}
                        color={"#e8ad05"}
                        isOnRightSide={false} distanceToTop={24}/>

            <SubCatPage text={"Unsere Kommandozentrale"}
                        main_text={"Unser Produktions- und Übertragungswagen ist ein kompaktes und dennoch wahres umweltfreundliches Innovationswunder."}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/10.jpg"}
                        color={"#e8ad05"}
                        isOnRightSide={true} distanceToTop={20}/>

            <SubCatPage text={"Neugierig?"}
                        main_text={"Kommen Sie gerne bei uns vorbei! Wir zeigen Ihnen gerne unsere Techniken, unsere \"Kommandozentrale\" und EvenMore"}
                        picture={"pictures/02 Fotos/04 Livestream/02 Livestream Seite/12.jpg"}
                        color={"#e8ad05"} isOnRightSide={true} distanceToTop={20}/>
        </div>
    );
};

export default Livestream;
