import React, {useEffect} from 'react';

import "../overlays.css"
import SubCatPage from "../../components/subcatpage/SubCatPage";
import SubCatPageHeader from "../../components/subcatpage/SubCatPageHeader";

const Events = () => {

    useEffect(() => {
        scrollToSection()
    }, []);
    const scrollToSection = (): void => {
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({behavior: "instant" as ScrollBehavior});
        }
    };

    return (
        <div>
            <SubCatPageHeader img={"pictures/03 evenMo Grafiken/Events/SVG/EVENTS WEISS GRUEN.svg"}/>
            <SubCatPage text={"Wir unterstützen Sie bei Ihrem Event!"}
                        picture={"pictures/02 Fotos/01 Events/02 Event Seite/evenMo Fm4.jpg"}
                        main_text={' Mit unserer kreativen Energie, Leidenschaft und Erfahrung gestalten wir Ihre Veranstaltung.'}
                        color={"#1db702"}
                        img_watermark={"FM4 TANZMITMIR; Postgarage; Graz"}
                        isOnRightSide={false}
                        distanceToTop={11}
            />

            <SubCatPage text={"jede art von event"}
                        picture={"pictures/02 Fotos/01 Events/02 Event Seite/evenMo Haus dem Meeres.jpg"}
                        main_text={"Von Sportevents über Hochzeiten bis hin zu Firmen- und Weihnachtsfeiern. Ihr Event in besten Händen."}
                        color={"#1db702"}
                        img_watermark={"Firmenevent; Haus des Meeres; Wien"}
                        isOnRightSide={true}
                        distanceToTop={20}
            />

            <SubCatPage text={"Die Richtige Technik"}
                        picture={"pictures/02 Fotos/01 Events/02 Event Seite/evenMo Hochzeit.jpg"}
                        color={"#1db702"}
                        main_text={"Mit unserer Expertise in Ton-, Licht- und LED-System-Technik schaffen wir die ideale Lösung."}
                        img_watermark={"Private Hochzeit; Attersee"}
                        isOnRightSide={false}
                        distanceToTop={27}

            />


            <SubCatPage text={"Ein Kontakt von Planung bis Umsetzung"}
                        picture={"pictures/02 Fotos/01 Events/02 Event Seite/bearbeitet_3x3_Day40746.jpg"}
                        color={"#1db702"}
                        main_text={" Das alles und EVENMORE für Ihren Event-Erfolg!"}
                        img_watermark={"3x3 Weltmeisterschafft; Wien"}
                        isOnRightSide={true}
                        distanceToTop={30}
            />
        </div>
    );
};

export default Events;