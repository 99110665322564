import React, {useEffect} from 'react';
import ZoomImage from "../../components/zoom_image/ZoomImage";
import SubCatPage from "../../components/subcatpage/SubCatPage";
import SubCatPageHeader from "../../components/subcatpage/SubCatPageHeader";

const Moderation = () => {
    useEffect(() => {
        scrollToSection()
    }, []);
    const scrollToSection = (): void => {
        const element = document.getElementById("start");
        if (element) {
            element.scrollIntoView({behavior: "instant" as ScrollBehavior});
        }
    };
    return (
        <div>
            <SubCatPageHeader img={"pictures/03 evenMo Grafiken/Moderation/SVG/Moderation weiß Blau.svg"}/>
            <SubCatPage text={"Rafael Dobler"}
                        main_text={"DER ALLESKÖNNER AM MIKROFON"}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/7.jpg"}
                        color={"#0990cc"}
                        isOnRightSide={true}
                        distanceToTop={20}
            />
            <SubCatPage text={"Pressekonferenzen / Live im TV"}
                        main_text={"EINES VORWEG: langweilige Moderationen? DAS HABE ICH NICHT IM REPERTOIRE. Es darf auch mal geschmunzelt werden; Spaß und Unterhaltung ist garantiert!"}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/8.jpg"}
                        color={"#0990cc"}
                        isOnRightSide={false}
                        distanceToTop={25}
            />
            <SubCatPage text={"Sportevents"}
                        main_text={"Sport ist meine pure Leidenschaft. Durch die richtigen Emotionen wird er erst lebendig, weshalb es immer wieder eine große Ehre und Freunde bereitet, den Sportevents und Sportlern meine Leidenschaft und Stimme zu geben."}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/9.jpg"}
                        color={"#0990cc"}
                        isOnRightSide={true}
                        distanceToTop={20}
            />
            <SubCatPage text={"Firmenevents"}
                        main_text={"Gestalten wir gemeinsam einen Abend, der Ihre Marke perfekt präsentiert und Ihren Kunden mit Sicherheit lange in Erinnerung bleibt."}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/10.jpg"}
                        color={"#0990cc"}
                        isOnRightSide={false} distanceToTop={26}/>
            <SubCatPage text={"Vom Stadion bis zum TV Studio"}
                        main_text={"Moderation kann man nur bedingt erlernen. Es ist eine Gabe! Und diese gebe ich mit voller Energie und Engagement in jedem Bereich wieder."}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/11.jpg"}
                        color={"#0990cc"}/>
            <SubCatPage text={"Ihr Event + meine Stimme = die Erfolgsformel"}
                        main_text={"Und EVENMORE herausragende und faszinierende Auftritte für Ihr Event."}
                        picture={"pictures/02 Fotos/02 Moderationen/02 Moderation Seite/12.jpg"}
                        color={"#0990cc"} isOnRightSide={true} distanceToTop={34}/>
        </div>
    );
};

export default Moderation;
