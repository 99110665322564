import React from 'react';
import "./kundenpartner.css"
import {Fade} from "react-awesome-reveal";

const eventSVGs = [
    { src: 'pictures/04 Referenzen kunden/01 Events/Blue Tomato.svg', url: 'https://www.blue-tomato.com/de-AT/?srsltid=AfmBOoo51C7cDHTHKQTrmvq8ekUs3euimfVM3lAGv2wMzESc-EKU8wd_' },
    { src: 'pictures/04 Referenzen kunden/01 Events/FM4.svg', url: 'https://fm4.orf.at/' },
    { src: 'pictures/04 Referenzen kunden/01 Events/Haus des Meeres.svg', url: 'https://www.haus-des-meeres.at/' },
    { src: 'pictures/04 Referenzen kunden/01 Events/HEAD.svg', url: 'https://www.head.com/de_AT/' },
    { src: 'pictures/04 Referenzen kunden/01 Events/HSG.svg', url: 'https://www.hsg-events.at/' },
];
const moderationSVGs = [
    { src: 'pictures/04 Referenzen kunden/02 Moderation/Basketball Austria.svg', url: 'https://basketballaustria.at/' },
    { src: 'pictures/04 Referenzen kunden/02 Moderation/FeelgoodFestival.svg', url: 'https://www.feelgood-festival.at/' },
    { src: 'pictures/04 Referenzen kunden/02 Moderation/kitzbühel.svg', url: 'https://hahnenkamm.com/' },
    { src: 'pictures/04 Referenzen kunden/02 Moderation/ORF.svg', url: 'https://orf.at/' },
    { src: 'pictures/04 Referenzen kunden/02 Moderation/ÖVV.svg', url: 'https://www.volleynet.at/' },
];
const entertainmentSVGs = [
    { src: 'pictures/04 Referenzen kunden/03 Entertainment/3x3.svg', url: 'https://fiba3x3.com/' },
    { src: 'pictures/04 Referenzen kunden/03 Entertainment/Basketball Superliga.svg', url: 'https://www.win2day.at/' },
    { src: 'pictures/04 Referenzen kunden/03 Entertainment/CEV.svg', url: 'https://www.cev.eu/' },
    { src: 'pictures/04 Referenzen kunden/03 Entertainment/FIVB.svg', url: 'https://en.volleyballworld.com/' },
    { src: 'pictures/04 Referenzen kunden/03 Entertainment/Lions.svg', url: 'https://www.traiskirchen-lions.at/' },
];
const livestreamSVGs = [
    { src: 'pictures/04 Referenzen kunden/04 Livestream/Bankenverband.svg', url: 'https://www.bankenverband.at/' },
    { src: 'pictures/04 Referenzen kunden/04 Livestream/BBRZ.svg', url: 'https://www.bbrz.at/de/bbrz' },
    { src: 'pictures/04 Referenzen kunden/04 Livestream/CEV.svg', url: 'https://www.cev.eu/' },
    { src: 'pictures/04 Referenzen kunden/04 Livestream/Fiba.svg', url: 'https://www.fiba.basketball/europecup/23-24' },
    { src: 'pictures/04 Referenzen kunden/04 Livestream/Loala1.svg', url: 'https://www.laola1.at/de/' },
];

const KundenPartnerContainer = () => {

    return (
        <div className="min-h-screen flex flex-col justify-center align-middle font-bold text-4xl overflow-hidden">
            <Fade cascade={true} direction="top-left" triggerOnce={true} delay={50}>
                <div className="textContainer">
                    <h1>KUNDEN UND PROJEKTREFERENZEN</h1>
                </div>
            </Fade>
            <Fade cascade={true} direction="left" triggerOnce={true} delay={100}>
                <div className="kundenpartner-category">
                    {eventSVGs.map(s => (
                        <a href={s.url} key={s.src} target="_blank" rel="noopener noreferrer">
                            <img src={s.src} alt={s.src.toLowerCase()} />
                        </a>
                    ))}
                </div>
            </Fade>
            <Fade cascade={true} direction="right" triggerOnce={true} delay={100}>
                <div className="kundenpartner-category">
                    {entertainmentSVGs.map(s => (
                        <a href={s.url} key={s.src} target="_blank" rel="noopener noreferrer">
                            <img src={s.src} alt={s.src.toLowerCase()} />
                        </a>
                    ))}
                </div>
            </Fade>
            <Fade cascade={true} direction="left" triggerOnce={true} delay={100}>
                <div className="kundenpartner-category">
                    {moderationSVGs.map(s => (
                        <a href={s.url} key={s.src} target="_blank" rel="noopener noreferrer">
                            <img src={s.src} alt={s.src.toLowerCase()} />
                        </a>
                    ))}
                </div>
            </Fade>
            <Fade cascade={true} direction="right" triggerOnce={true} delay={100}>
                <div className="kundenpartner-category">
                    {livestreamSVGs.map(s => (
                        <a href={s.url} key={s.src} target="_blank" rel="noopener noreferrer">
                            <img src={s.src} alt={s.src.toLowerCase()} />
                        </a>
                    ))}
                </div>
            </Fade>
        </div>
    );
};

export default KundenPartnerContainer;
