/**
 * author: simon
 * date: 31.08.2024
 * project: EvenMoWebsite
 * package_name:
 **/
import "./kontaktpopup.css"
import React from 'react';


interface PopUpProps {
    isOpen: boolean;
    onClose: () => void;
}

const KontaktPopUp:React.FC<PopUpProps> = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <h4>Kontakt</h4>
                <hr/>
                <div>
                    <h6>Telefonnummer:</h6>
                    <a href={"tel:+436502071180"}><h5>+43 650 2071180</h5></a>
                </div>
                <div>
                    <h6>Email:</h6>
                    <a href={"mailto:dobler@evenmo.at"}><h5>dobler@evenmo.at</h5></a>
                </div>
            </div>
        </div>
    );
};

export default KontaktPopUp;