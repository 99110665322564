/**
 * author: simon
 * date: 02.08.2024
 * project: EvenMoWebsite
 * package_name:
 **/

import React from 'react';
import {SubCatPagePropsDesktop} from "./SubcatDesktop";
import "./subcatmobile.css"
import {Fade} from "react-awesome-reveal";

const SubcatMobile: React.FC<SubCatPagePropsDesktop> = ({
                                                            text,
                                                            picture,
                                                            main_text,
                                                            img_watermark_link,
                                                            img_watermark,
                                                            curText,
                                                            color,
                                                            isOnRightSide,
                                                            distanceToTop
                                                        }) => {

    return (
        <div className={"subCatMobile"}>
            <img src={picture} alt="pic"/>
            <Fade cascade={true} direction={"left"} triggerOnce={true} delay={100}>
                <div className="headline" style={{backgroundColor: color}}>
                    {text.toUpperCase()}
                </div>
            </Fade>
            <Fade cascade={true} direction={"left"} triggerOnce={true} delay={100}>
                <div className="main-text">
                    {main_text}
                </div>
            </Fade>
        </div>
    );
};

export default SubcatMobile;